import { CITIZENS_SCRIPT } from '@/services/Configuration'
import Script from 'next/script'

const CitizensPay: React.FC = () => (
  <Script
    strategy="afterInteractive"
    data-testid="citizens-script"
    src={CITIZENS_SCRIPT}
    onLoad={() => document.dispatchEvent(new Event('CitizensPayScriptLoaded'))}
  />
)

export default CitizensPay
